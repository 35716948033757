#about-page {
  /* Media query for tablets and larger mobile devices */
  /* Media query for laptops and larger devices */ }
  #about-page .about_header {
    font-size: 34px;
    font-weight: 600;
    text-transform: uppercase; }
  #about-page .first_about_bg {
    background-image: url(./../../assets/images/about/about_text_background_1.png);
    height: 400px;
    background-repeat: no-repeat;
    background-size: cover; }
  #about-page .secondPart_bg_img {
    background-image: url(./../../assets/images/about/about_bg_2.png);
    height: 700px;
    background-repeat: no-repeat;
    background-size: cover; }
  #about-page .secondpart_white_bg {
    background: #fff;
    box-shadow: 1px 0px 20px 5px #00000052;
    border-radius: 25px; }
  #about-page .third_about_pg {
    background-image: url(./../../assets/images/about/about_text_background_2.png);
    height: 400px;
    background-repeat: no-repeat;
    background-size: contain; }
  #about-page .img_border {
    border: 4px solid blue;
    border-radius: 25px; }
  #about-page .content_heading {
    font-size: 16px;
    font-weight: 700;
    color: #e50046; }
  #about-page .content_para {
    text-align: justify;
    font-weight: 500; }
  #about-page .list_content li {
    line-height: 2.0; }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    #about-page .third_about_pg {
      height: 900px;
      background-size: cover; } }
  @media only screen and (max-width: 668px) and (min-width: 420px) {
    #about-page .third_about_pg {
      height: 1400px;
      background-size: cover; } }
  @media only screen and (max-width: 419px) and (min-width: 320px) {
    #about-page .third_about_pg {
      height: 2200px;
      background-size: cover; } }
  @media only screen and (max-width: 763px) and (min-width: 669px) {
    #about-page .third_about_pg {
      height: 1100px;
      background-size: cover; } }
  @media (min-width: 1024px) and (max-width: 1040px) {
    #about-page .third_about_pg {
      height: 500px; } }
