#header {
  /* Media query for mobile devices */
  /* Media query for tablets and larger mobile devices */
  /* Media query for laptops and larger devices */ }
  #header .header_bg {
    background-color: #040430;
    height: 600px; }
  #header .active {
    color: #22387e !important; }
  #header .logo {
    padding-top: 10px;
    padding-bottom: 30px;
    text-align: center;
    margin: auto;
    background-color: #fff; }
  #header .navbar_bgcolor {
    border-radius: 50px;
    position: relative;
    top: -27px;
    z-index: 9999;
    background-color: #e71f55; }
  #header .header_bg_img {
    background-image: url(./../../assets/images/hedder.png);
    height: 580px;
    background-repeat: no-repeat;
    border-radius: 50px;
    background-size: cover;
    position: relative;
    z-index: 99;
    top: -60px; }
  #header .atglance_header_bg_img {
    background-image: url(./../../assets/images/glance/atglance-header-Image.png);
    height: 550px;
    background-repeat: no-repeat;
    border-radius: 50px;
    background-size: cover;
    position: relative;
    z-index: 99;
    top: -60px; }
  #header .ourservice_header_bg_img {
    background-image: url(./../../assets/images/our-service/header_img.png);
    height: 550px;
    background-repeat: no-repeat;
    border-radius: 50px;
    background-size: cover;
    position: relative;
    z-index: 99;
    top: -60px; }
  #header .contact_header_bg_img {
    background-image: url(./../../assets/images/contact_bg.png);
    height: 550px;
    background-repeat: no-repeat;
    border-radius: 50px;
    background-size: cover;
    position: relative;
    z-index: 99;
    top: -60px; }
  #header .about_header_bg_img {
    background-image: url(./../../assets/images/about/about-header-Img.png);
    height: 550px;
    background-repeat: no-repeat;
    border-radius: 50px;
    background-size: cover;
    position: relative;
    z-index: 99;
    top: -60px; }
  #header .nav-link {
    font-weight: 600;
    text-transform: uppercase;
    padding: 38px;
    color: #fff; }
  #header .logo-img {
    width: 250px; }
  #header .navbar-nav {
    margin: auto; }
  #header .header_bg_img_text {
    position: absolute;
    left: 0;
    right: 0;
    font-size: 34px;
    width: 72%;
    margin: auto;
    bottom: 37%;
    font-weight: 800;
    color: #fff; }
  #header .contact_header_bg_img_text {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 34px;
    width: 100%;
    margin: auto;
    top: 25%;
    font-weight: 800;
    color: #fff; }
  #header .z-index-999 {
    z-index: 999; }
  #header #carouselExampleDark {
    height: 218px;
    position: relative;
    bottom: 100px;
    background: #fff;
    border-radius: 25px;
    box-shadow: 0px 3px 48px 5px #aac3e4;
    padding-top: 20px; }
  #header .carousel-item {
    height: 200px; }
  #header .carousel-caption {
    bottom: 3.25rem !important; }
  #header .header_text {
    font-size: 18px;
    font-weight: 800;
    color: #eb254b; }
  #header .description {
    font-size: 16px;
    text-align: justify; }
  #header #sideNav {
    display: none;
    position: absolute;
    top: 6%;
    left: 15px; }
  #header .sidenavbar_menu_btn {
    background: #fff;
    color: #000;
    padding: 8px 16px; }
  #header #sideNav .nav-link {
    color: #000;
    padding: 14px; }
  @media (max-width: 768px) and (min-width: 320px) {
    #header .navbar {
      display: none; }
    #header #sideNav {
      display: block; }
    #header .carousel_section {
      margin-left: 0; } }
  @media (max-width: 768px) and (min-width: 601px) {
    #header .dynamic_heading_text {
      font-size: 20px !important; } }
  @media (max-width: 1024px) and (min-width: 768px) {
    #header .logo-img {
      padding-bottom: 30px; } }
  @media (max-width: 600px) and (min-width: 320px) {
    #header .dynamic_heading_text {
      font-size: 14px !important; }
    #header .contact_header_bg_img_text {
      left: 0 !important;
      font-size: 15px;
      text-align: center; }
    #header .about-heading {
      padding: 48px 26px !important;
      font-size: 14px !important; } }
  @media only screen and (max-width: 767px) {
    #header {
      /* Styles for mobile devices */ }
      #header .logo {
        width: 200px;
        background: transparent; }
      #header .logo-img {
        width: 200px;
        background: transparent; }
      #header .atglance_header_bg_img, #header .ourservice_header_bg_img, #header .about_header_bg_img, #header .contact_header_bg_img {
        top: 0px; }
      #header .navbar_bgcolor {
        top: 10px; }
      #header .header_bg_img {
        height: 460px;
        top: 0px; }
      #header .header_bg_img_text {
        width: 100%;
        left: 10px;
        font-size: 24px; }
      #header .header_text {
        font-size: 14px;
        font-weight: 800; }
      #header .description {
        font-size: 12px; }
      #header .carousel-caption {
        bottom: 1.25rem !important; } }
  #header .dynamic_heading_text {
    padding: 45px;
    font-size: 22px;
    font-weight: 600;
    font-style: italic; }
  #header .about-heading {
    padding: 54px 50px;
    font-style: italic;
    font-weight: 600;
    font-size: 20px; }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    #header {
      /* Styles for tablets */ }
      #header .navbar_bgcolor {
        top: 30px; }
      #header .header_bg_img {
        height: 500px; }
      #header .header_bg_img_text {
        bottom: 0;
        top: 22%;
        padding: 0 25px;
        width: 100%; } }
  @media (min-width: 1024px) and (max-width: 1040px) {
    #header {
      /* Styles for laptops and larger devices */ }
      #header .nav-link {
        font-weight: 600;
        text-transform: uppercase;
        padding: 16px !important; } }
