#service_section {
  margin-top: 24%; }
  #service_section .serive_header_title {
    font-size: 24px;
    font-weight: 700;
    margin-top: 25px;
    margin-bottom: 40px; }
  #service_section .service_section1_title {
    text-align: right;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 12px; }
  #service_section .service_section2_title {
    text-align: left;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 12px; }
  #service_section .service_section1_description {
    text-align: justify;
    font-size: 14px;
    letter-spacing: 0.2px; }
  #service_section .first_section_icon {
    background: #0283fe;
    border-radius: 50px;
    padding: 15px;
    color: #fff;
    font-size: 26px;
    position: relative;
    top: 37px; }
  #service_section .img_line_section_left {
    position: relative; }
    #service_section .img_line_section_left .bottom_line {
      border-right: 2px solid #0283fe;
      position: absolute;
      height: 190px;
      right: 60%;
      margin-top: 0px; }
  #service_section .img_line_section_right {
    position: relative; }
    #service_section .img_line_section_right .bottom_line {
      border-right: 2px solid #0283fe;
      position: absolute;
      height: 190px;
      right: 50%;
      margin-top: 0px; }
  #service_section .side-img {
    background: #0283fe;
    border-radius: 50px;
    width: 60px;
    padding: 12px;
    margin-top: 60px; }
  @media (max-width: 768px) and (min-width: 700px) {
    #service_section .img_line_section_left {
      position: relative; }
      #service_section .img_line_section_left .bottom_line {
        border-right: 2px solid #0283fe;
        position: absolute;
        height: 49px;
        margin-top: 6px;
        right: 80%; } }
  @media (max-width: 1024px) and (min-width: 780px) {
    #service_section .service_section2_title {
      font-size: 14px; }
    #service_section .service_section1_description {
      font-size: 12px; }
    #service_section .img_line_section_left {
      position: relative; }
      #service_section .img_line_section_left .bottom_line {
        border-right: 2px solid #0283fe;
        position: absolute;
        height: 100px; }
    #service_section .img_line_section_right {
      position: relative; }
      #service_section .img_line_section_right .bottom_line {
        border-right: 2px solid #0283fe;
        position: absolute;
        height: 100px; } }
