#ourservice-page {
  background: #ecf2ff;
  background-image: url("./../../assets/images/our-service/service_bg.png");
  height: auto; }
  #ourservice-page .service_heading {
    margin-top: 230px !important;
    font-size: 34px;
    font-weight: 800;
    color: #23377c;
    text-transform: uppercase;
    background: #ecf2ff; }
  #ourservice-page .heading_border {
    border: 2px solid #f47075;
    width: 200px;
    text-align: center;
    margin: auto; }
  #ourservice-page .service_sub_heading {
    color: #e71853;
    font-weight: 600;
    font-style: italic;
    margin-bottom: 20px; }
  #ourservice-page .heading {
    color: #23377c;
    font-weight: 700;
    font-size: 22px; }
  #ourservice-page .sub_heading {
    color: #e71853;
    font-weight: 600; }
  #ourservice-page .para {
    font-size: 14px;
    font-weight: 600;
    text-align: justify;
    line-height: 1.8; }
  #ourservice-page .para_ul ul li {
    font-size: 14px;
    font-weight: 600;
    line-height: 2.5; }
  #ourservice-page .normal_text {
    font-size: 14px;
    font-weight: 600;
    line-height: 2.0; }
    #ourservice-page .normal_text div {
      margin-left: 25px; }
  #ourservice-page .additional_serbvice {
    padding: 5px 5px 2px 80px;
    border-radius: 40px;
    position: relative;
    top: 180px; }
  #ourservice-page .third_section_bg {
    background-image: url(./../../assets/images/our-service/bg_img.jpg);
    height: 400px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat; }
  @media (max-width: 768px) and (min-width: 320px) {
    #ourservice-page .additional_serbvice {
      padding: 5px 25px 2px 25px;
      border-radius: 40px;
      position: relative;
      top: 180px; } }
