#ourServiceCarousel {
  background-image: url("./../../assets/images/our-services.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 950px;
  width: 100%; }
  #ourServiceCarousel .header_title {
    font-size: 26px;
    font-weight: 700;
    margin-top: 45px;
    margin-bottom: 20px;
    text-align: center;
    color: #fff; }
  #ourServiceCarousel .carousel-container {
    height: 600px; }
  #ourServiceCarousel .carousel_section {
    margin-left: 30px;
    height: auto;
    margin-top: 20px;
    border: 1px solid #13376a;
    border-radius: 25px; }
  #ourServiceCarousel .border_radius_25 {
    border-top-right-radius: 25px;
    border-top-left-radius: 25px; }
  #ourServiceCarousel .description_text {
    font-size: 14px;
    margin-top: 10px;
    height: 105px;
    overflow-y: hidden;
    height: 100px; }
  #ourServiceCarousel .description {
    background: #fff;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    padding: 20px;
    text-align: justify; }
    #ourServiceCarousel .description a {
      margin-top: 30px;
      text-align: center;
      border-radius: 50px;
      font-size: 12px;
      padding: 6px 15px;
      background: #fff;
      color: #000; }
      #ourServiceCarousel .description a:hover {
        background: #0b5ed7;
        color: #fff; }
  #ourServiceCarousel .sub_heading {
    font-style: italic;
    text-align: center;
    color: #fff; }
  @media (max-width: 748px) and (min-width: 320px) {
    #ourServiceCarousel .carousel_section {
      margin-left: 0;
      margin-bottom: 40px; } }
  @media (max-width: 768px) and (min-width: 748px) {
    #ourServiceCarousel .carousel_section {
      margin-left: 20;
      margin-bottom: 40px; } }
