#footer-page {
  background-image: url(./../../assets/images/Footer.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  background-color: #000000f0;
  border-top: 5px solid #2e2eed;
  padding-top: 80px; }
  #footer-page .footer_logo {
    width: 250px; }
  #footer-page .twitter_rigt_margin, #footer-page .facebook_rigt_margin {
    margin-right: 20px; }
  #footer-page .twitter_rigt_margin, #footer-page .facebook_rigt_margin {
    color: #fff;
    border: 1px solid #0082fe;
    padding: 10px 14px;
    background-color: #0082fe;
    border-radius: 50px; }
  #footer-page .insta_rigt_margin {
    color: #fff;
    border: 1px solid #0082fe;
    padding: 10px 14px 10px 10px;
    background-color: #0082fe;
    border-radius: 50px; }
  #footer-page .border_right_line {
    border-right: 1px solid #fff; }
  #footer-page .presence_text {
    color: #0082fe;
    font-size: 24px;
    font-weight: 600;
    text-align: center; }
  #footer-page .footer_presence_text, #footer-page a {
    color: #fff;
    text-decoration: none;
    font-size: 16px; }
  #footer-page .city_name {
    font-weight: 700;
    margin-bottom: 20px; }
