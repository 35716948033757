#contact-address-page {
  /* Media query for mobile devices */
  /* Media query for tablets and larger mobile devices */
  /* Media query for laptops and larger devices */ }
  #contact-address-page .header_text {
    font-size: 24px;
    font-weight: 700;
    margin-top: 25px;
    margin-bottom: 40px;
    text-align: center; }
  #contact-address-page .contact_bg {
    border: 1px solid #f8fbfe;
    background-color: #f8fbfe;
    border-radius: 25px;
    margin-top: 25%;
    margin-bottom: 100px;
    height: auto;
    padding-bottom: 50px; }
  #contact-address-page .address_container {
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
    background: #fff;
    padding: 10px 25px;
    height: auto;
    box-shadow: 1px 2px 15px #e7f3fc;
    font-size: 14px; }
  #contact-address-page .head_office_line {
    border-right: 2px solid #e7f3fc;
    margin-right: 20px;
    height: 145px;
    margin-bottom: 5px; }
  #contact-address-page .box_shadow_inpt {
    box-shadow: 1px 2px 15px #e7f3fc; }
  #contact-address-page .office_text {
    margin-top: 10px;
    font-weight: 700;
    color: #000;
    margin-bottom: 15px; }
  #contact-address-page .p-r-10 {
    padding-right: 10px; }
  #contact-address-page .address_icon {
    display: flex; }
  #contact-address-page .icon_right_2 {
    margin-right: 12px; }
  #contact-address-page input::placeholder {
    font-size: 14px;
    font-weight: 600;
    color: #818181; }
  #contact-address-page textarea::placeholder {
    font-size: 14px;
    color: #818181; }
  #contact-address-page .contact_us_text {
    font-size: 16px;
    font-weight: 700; }
  #contact-address-page .contact_bg {
    padding: 0px 30px 35px 35px; }
  #contact-address-page .message_text {
    font-size: 20px;
    font-weight: 700; }
  #contact-address-page .error-message {
    color: red;
    font-size: 12px; }
  #contact-address-page .success-message {
    color: green;
    font-size: 14px; }
  #contact-address-page .number {
    text-decoration: none;
    color: #000; }
  @media (max-width: 768px) and (min-width: 320px) {
    #contact-address-page .contact_bg {
      bottom: 20%; } }
  @media only screen and (max-width: 767px) {
    #contact-address-page {
      /* Styles for mobile devices */ }
      #contact-address-page .contact_bg {
        padding: 0px 30px 35px 35px; }
      #contact-address-page .address_container {
        display: block; }
      #contact-address-page .head_office_line {
        border: 0; }
      #contact-address-page .branch_office {
        margin-top: 40px; } }
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    #contact-address-page {
      /* Styles for tablets */ }
      #contact-address-page .contact_bg {
        padding: 0px 30px 35px 35px; } }
  @media only screen and (min-width: 1024px) {
    #contact-address-page {
      /* Styles for laptops and larger devices */ }
      #contact-address-page .contact_bg {
        padding: 0px 30px 35px 35px; } }
