#atGlance-page {
  background: #ecf2ff; }
  #atGlance-page .glance_heading {
    margin-top: 230px !important;
    font-size: 34px;
    font-weight: 800;
    color: #23377c;
    text-transform: uppercase;
    background: #ecf2ff; }
  #atGlance-page .heading_border {
    border: 2px solid #f47075;
    width: 250px;
    text-align: center;
    margin: auto; }
  #atGlance-page .heading {
    color: #e71853;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 15px;
    text-transform: uppercase; }
  #atGlance-page .paragraph {
    margin-bottom: 20px;
    font-weight: 500;
    text-align: justify; }
  @media (max-width: 424px) and (min-width: 320px) {
    #atGlance-page .glance_heading {
      font-size: 24px; }
    #atGlance-page .heading_border {
      width: 180px; } }
