body {
  margin: 0;
  /* font-family: 'Open Sans', sans-serif ; */
  font-family: 'Montserrat', sans-serif !important;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 800 !important;
}

.font-size-32-800 {
  font-size: 32px;
  font-weight: 800;
}

.font-size-30-800 {
  font-size: 30px;
  font-weight: 800;
}
.font-size-28-800 {
  font-size: 28px;
  font-weight: 800;
}

.font-size-26-800 {
  font-size: 26px;
  font-weight: 800;
}

.font-size-24-800 {
  font-size: 24px;
  font-weight: 800;
}

.font-size-22-800 {
  font-size: 22px;
  font-weight: 800;
}

.font-size-20-800 {
  font-size: 20px;
  font-weight: 800;
}

.font-size-18-800 {
  font-size: 18px;
  font-weight: 800;
}

.font-size-16-800 {
  font-size: 16px;
  font-weight: 800;
}
.font-size-14-800 {
  font-size: 14px;
  font-weight: 800;
}

.font-size-12-800 {
  font-size: 12px;
  font-weight: 800;
}

.font-size-20-600 {
  font-size: 20px;
  font-weight: 600;
}

.font-size-18-600 {
  font-size: 18px;
  font-weight: 600;
}
.font-size-16-600 {
  font-size: 16px;
  font-weight: 600;
}